<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="名称" span="6">
          <vxe-input v-model="listQuery.param.name" placeholder="请输入名称" clearable />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="viewStatus" slot-scope="{row}">
          <el-button v-if="row.status==0" size="small" type="text" @click="openWkFlowDialog(row.id)">点击审核</el-button>
          <el-button v-if="row.status==1" size="small" type="success" disabled>已通过</el-button>
          <el-button v-if="row.status==-1" size="small" type="info" disabled >未通过</el-button>
        </template>
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'VolunteerTeamVolunteer'" size="small" type="text" @click="goVolunteerTeamVolunteer(row.id)">小组成员</el-button>
      </template>
    </CustomList>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
export default {
  name: 'Team',
  components: { CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      commitMsg:"",
      listQuery: {
        param: {}
      },
      tableColumns: [
        {
          prop: 'name',
          title: '名称',
          minWidth: 150
        },
        {
          title: '审核状态',
          minWidth: 150,
          slot: 'viewStatus'
        },
        {
          title: '创建用户',
          formatter: (row, column) => {
            return row.createUser ? `${row.createUser.name} <br /> ${row.createUser.phone}` : ''
          },
          minWidth: 150
        },
        {
          title: '团队队长',
          formatter: (row, column) => {
            return row.createUser ? `${row.createUser.name} <br /> ${row.createUser.phone}` : ''
          },
          minWidth: 150
        },
        {
          prop: 'description',
          title: '小组介绍',
          minWidth: 200
        },
        {
          prop: 'memberCount',
          title: '小组成员数',
          minWidth: 120
        },
        {
          prop: 'credits',
          title: '小组总积分',
          minWidth: 120
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 100
        }
      ]
    }
  },
  methods: {
    ...mapActions(['volunteerTeamPublicPageList','gotoDecisionMakeMethod']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {

        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
  
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      params=Object.assign(params,{'param':{"allView":1}})
      this.volunteerTeamPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    goVolunteerTeamVolunteer(teamId) {
      this.$router.push({ name: 'VolunteerTeamVolunteer', params: { teamId }})
    },

    gotoDecisionMaking(rowid,status){

      this.gotoDecisionMakeMethod({"teamId":rowid,"sciMsg":this.commitMsg,"status":status}).then(response => {
   
        this.handleSearch()

        this.$message.success('操作成功')
      }).catch(() => {})
    },
    async openWkFlowDialog(rowid) {
        let status =0
        await this.$prompt('说明理由', '提示', {
           confirmButtonText: '拒绝',
           cancelButtonText: '同意',
         }).then(({ value }) => {
          //拒绝 
          this.commitMsg =value 
           status =-1
         }).catch((val) => {
           //同意  
           this.commitMsg ="同意"
           status =1
 
         }); 
        //提交到后台
         this.gotoDecisionMaking(rowid,status)
     }

    }
  }

</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
