<template>
  <div class="table">
    <el-table
      v-loading="listLoading"
      :data="accountList"
      :stripe="stripe"
      :border="border"
      :highlight-current-row="highlightCurrentRow"
      :row-key="rowKey"
      :default-expand-all="defaultExpandAll"
      :tree-props="treeProps"
      style="width: 100%;"
    >
      <el-table-column
        v-for="(column, index) in columns"
        :key="index"
        :type="column.type"
        :label="column.title || ''"
        :prop="column.prop"
        :width="column.width"
        :min-width="column.minWidth"
        :fixed="column.fixed"
        :sortable="column.sortable ? 'custom' : false"
        show-overflow-tooltip
        :align="column.align || 'center'"
        :header-align="column.headerAlign || 'center'"
      >
        <template slot-scope="{row}">
          <slot v-if="column.slot" :name="column.slot" :row="row" :column="column" />
          <template v-else-if="!column.render">
            <template v-if="column.formatter">
              <span v-html="column.formatter(row, column)" />
            </template>
            <template v-else>
              <span>{{ row[column.prop] }}</span>
            </template>
          </template>
          <!--render函数-->
          <template v-else>
            <expandDom :column="column" :row="row" :render="column.render" :index="index" />
          </template>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div v-if="tablePage.totalResult > tablePage.pageSize" class="page">
      <vxe-pager
        :current-page.sync="tablePage.current"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      />
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import tools from '@/utils/tools'

export default {
  name: 'CustomList',
  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: (h, ctx) => {
        const params = {
          row: ctx.props.row,
          index: ctx.props.index
        }
        if (ctx.props.column) params.column = ctx.props.column
        return ctx.props.render(h, params)
      }
    }
  },
  props: {
    stripe: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    highlightCurrentRow: {
      type: Boolean,
      default: true
    },
    rowKey: {
      type: String,
      default: ''
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    treeProps: {
      type: Object,
      default: () => {
        return { hasChildren: '', children: '' }
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    listQuery: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      listLoading: false,
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      accountList: []
    }
  },
  computed: {
    ...mapState(['layouts'])
  },
  created() {
    this.getList()
  },
  methods: {
    getList(resetCurrentPage = false) {
      this.listLoading = true
      const params = tools.deepClone(this.listQuery)
      if (resetCurrentPage) {
        this.tablePage.currentPage = 1
      }
      params['current'] = this.tablePage.currentPage
      params['size'] = this.tablePage.pageSize
      this.$emit('getList', params, res => {
        if (res.code !== 200) {
          this.tablePage.totalResult = 0
          this.accountList = []
          setTimeout(() => { this.listLoading = false }, 500)
          this.$message.error(res.msg)
          return
        }
        this.tablePage.totalResult = res.data.total
        this.accountList = res.data.records
        setTimeout(() => { this.listLoading = false }, 500)
      })
    },
    pageChange(item) {
      if (item.type === 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-table {
    width: 100%;
  }
</style>
